<template>
    <td :class="classes">
        <slot />
    </td>
</template>

<script>
export default {
    props: {
        fullWidth: {
            type: Boolean,
            required: false,
            default: () => true,
        },
        autoWidth: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        whitespaceNoWrap: {
            type: Boolean,
            required: false,
            default: () => true,
        },
        plain: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        hasBorder: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        align: {
            type: String,
            required: false,
            default: () => "left",
        },
    },
    data() {
        return {
            baseClasses: "py-4 px-default text-sm text-gray-500",
        };
    },
    computed: {
        classes() {
            return `table-data ${this.plain ? "" : this.baseClasses} ${this.whitespaceNoWrap ? "whitespace-nowrap" : ""} ${this.fullWidth ? "w-full" : ""} ${this.autoWidth ? "w-auto" : ""} text-${this.align}`;
        },
    },
};
</script>
